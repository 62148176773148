/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
         $( "select" ).wrap( "<div class='select-wrap'></div>" );
        
        
         ///Menu///
        $('#menu-primary-navigation').smartmenus({ });
        
          
        function runMenuToggle()
        { 
          $( "#menu-primary-navigation" ).slideToggle();
        }
        
        $( "#menu-trigger" ).click(function()
        {
          runMenuToggle();
        });
        
        $(window).resize(function()
        {
          if ($(window).width() > 767)
          {
            $( "#menu-primary-navigation" ).css('display', 'block');
          }
          else
          {
            $( "#menu-primary-navigation" ).css('display', 'none');
          }
          
        });
        
        $(".sm-simple > li").click(function() {
          var href = $(this).find("a").attr("href");
            if(href && ($(window).width() > 767)) {
               window.location = href;
            }
        });
        
        ////End Menu////
        
        
        /* Gallery */
        
          function setGalleryItemHeight(){
            //console.log("footer align");
            if ($(window).width() < 992) {
              $(".gallery-item").height('auto');
              var maxFoot = Math.max.apply(Math, $(".gallery-item").map(function() { return $(this).height(); }));
                $(".gallery-item").each(function() {
                  $(this).height(maxFoot);
                });
            }else{
                $(".gallery-item").height('auto');
            }
          }
          
          
          function runEffectSearch() { 
            var toggleWidth = $(".toggle-search-container .inner").width() === 300 ? "0px" : "300px";
            $('.toggle-search-container .inner').animate({ width: toggleWidth });
          }
          
          // set effect from select menu value
          $( ".trigger-search" ).click(function() {
            //console.log("toggled");
            runEffectSearch();
          });
          
          
          function archiveAlign(){
            $(".archive-item .content").height('auto');
            
            var max = Math.max.apply(Math, $(".archive-item .content").map(function() { return $(this).height(); }));
                $(".archive-item .content").each(function() {
                $(this).height(max);
            }); 
              
          }
          
          function rangeAlign(){
            $rangeHeight = $('#main-image .inner').height();
            $headerHeight = $('.range-back-thumbs h3').height() + 10;
            $totalHeight = $rangeHeight - $headerHeight;
            $('.range-back-thumbs .scroll').animate({"height": $totalHeight}, function() {  
                $(".range-back-thumbs .scroll").mCustomScrollbar({
                  theme:"dark",
                  alwaysShowScrollbar: 2,
                  scrollButtons: { enable: true }
                });
           });
          }
        
          $(window).load(function() {
            archiveAlign();
            rangeAlign();
          });
        
        
          $(window).resize(function()
          {
            setGalleryItemHeight();      
            archiveAlign();
            rangeAlign();
          });
          
          
          //$('a[href*="#"]:not([href="#"])').click(function() {
          //  if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
          //    var target = $(this.hash);
          //    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          //    if (target.length) {
          //      $('html, body').animate({
          //        scrollTop: target.offset().top-160
          //      }, 1000);
          //      return false;
          //    }
          //  }
          //});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
